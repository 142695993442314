






import { defineComponent } from "@vue/composition-api"

import ClientCreateUpdate from "@/view/Client/component/ClientCreateUpdate.vue"
import { createDefaultClientData } from "@/_service/client"

export default defineComponent({
  name: "ClientCreate",

  components: { ClientCreateUpdate },

  setup() {
    const clientData = createDefaultClientData()

    return {
      clientData,
    }
  },
})
